import {_, React, Component, className, lib} from '@henrybuilt/react-app'; //eslint-disable-line
import {Form} from 'henrybuilt-react-library';
import PaymentMethodBody from '../payment-method/payment-method-body/payment-method-body.js';
import './ach-form.scss';

export default class AchForm extends PaymentMethodBody {
  get formKeys() {
    return [
      'achReferenceNumber'
    ];
  }

  async onSubmit() {
    await super.onSubmit({
      processTransaction: async () => {
        this.props.onClientInitializedPayment({paymentType: 'ach', supportingInfo: this.state.achReferenceNumber});
      },
      incompleteFormMessage: 'Please enter an ach reference number'
    });
  }

  render() {
    var {transaction, isInitializedByClient, legalCompanyName, companySpecificAccountNumber} = this.props;
    var {state} = this;

    return !transaction ? null : (
      <>
        <div className='static-billing-container ach-reference-container'>
          <div className='static-billing-line-item'>Please Include Your Name and transaction #{transaction.projectId || transaction.id} In The Memo</div>
          <div className='static-billing-line-item'>
            <div className='static-billing-label'>ACH payments should be sent to:</div>
            <div className='static-billing-content-container'>
              <div className='static-billing-content'>{legalCompanyName}</div>
              <div className='static-billing-content'>Bank of America</div>
              <div className='static-billing-content'>222 Broadway</div>
              <div className='static-billing-content'>New York, NY 10038</div>
            </div>
          </div>
          <div className='static-billing-line-item'>
            <div className='static-billing-label'>Routing/ABA #:</div>
            <div className='static-billing-content-container'>
              <div className='static-billing-content'>125000024</div>
            </div>
          </div>
          <div className='static-billing-line-item'>
            <div className='static-billing-label'>Account #:</div>
            <div className='static-billing-content-container'>
              <div className='static-billing-content'>{companySpecificAccountNumber}</div>
            </div>
          </div>
          <br/>
          {!isInitializedByClient && <div className='ach-reference-number-form-container'>
            <div className='static-billing-line-item'>After an ACH Payment has been initiated with your bank, enter the reference number and click the button below</div>
            <Form
              useSubmitInput
              on={{submit: this.onSubmit}}
              submitText={`I initiated an ACH payment for ${lib.price.dinero(this.remainingAmountDue / 100).toFormat('$0,0.00')}`}
              isSubmittingText='Notifying Our Team'
            >
              <Form.TextInput {...className([_.includes(this.invalidKeys, this.state.hasSubmitted && 'achReferenceNumber') && 'is-invalid', 'ach-reference-number-input'])} label='ACH Reference #' placeholder='' value={state.achReferenceNumber} on={{input: ({value}) => this.setState({achReferenceNumber: value})}}/>
            </Form>
          </div>}
        </div>
      </>
    );
  }
}
