import {_, React, Component, className, lib} from '@henrybuilt/react-app'; //eslint-disable-line
import {Form} from 'henrybuilt-react-library';
import PaymentMethodBody from '../payment-method/payment-method-body/payment-method-body.js';
import './check-form.scss';

export default class CheckForm extends PaymentMethodBody {
  get formKeys() {
    return [
      'checkReferenceNumber'
    ];
  }

  async onSubmit() {
    await super.onSubmit({
      processTransaction: async () => {
        this.props.onClientInitializedPayment({paymentType: 'check', supportingInfo: this.state.checkReferenceNumber});
      },
      incompleteFormMessage: 'Please enter a check or tracking number'
    });
  }

  render() {
    var {transaction, isInitializedByClient, companyName, companySpecificPOBoxNumber} = this.props;
    var {state} = this;

    return !transaction ? null : (
      <>
        <div className='static-billing-container check-reference-container'>
        <div className='static-billing-line-item'>Please Include Your Name and transaction #{transaction.projectId || transaction.id} In The Memo</div>
          <div className='static-billing-line-item'>
            <div className='static-billing-label'>Checks should be mailed via USPS to:</div>
            <div className='static-billing-content-container'>
              <div className='static-billing-content'>{companyName}</div>
              <div className='static-billing-content'>PO Box {companySpecificPOBoxNumber}</div>
              <div className='static-billing-content'>Seattle, WA 98108</div>
            </div>
          </div>
          <br/>
          {!isInitializedByClient && <div className='check-reference-number-form-container'>
            <div className='static-billing-line-item'>After a check has been mailed via USPS, enter the check number or tracking number and click the button below</div>
            <Form
              useSubmitInput
              on={{submit: this.onSubmit}}
              submitText={`I sent a check for for ${lib.price.dinero(this.remainingAmountDue / 100).toFormat('$0,0.00')}`}
              isSubmittingText='Notifying Our Team'
            >
              <Form.TextInput {...className([_.includes(this.invalidKeys, this.state.hasSubmitted && 'checkReferenceNumber') && 'is-invalid', 'check-reference-number-input'])} label='Check or Tracking #' placeholder='' value={state.checkReferenceNumber} on={{input: ({value}) => this.setState({checkReferenceNumber: value})}}/>
            </Form>
          </div>}
        </div>
      </>
    );
  }
}
