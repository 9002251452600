import {_, React, Component, className, lib} from '@henrybuilt/react-app'; //eslint-disable-line
import {Form} from 'henrybuilt-react-library';

export default class PaymentMethodBody extends Component {
  state={
    partialPaymentAmount: 0
  };

  constructor(props) {
    super(props);

    this.bind(['onSubmit']);
  }

  handleChangeFor({key}) {
    return ({value}) => this.setState({[key]: value});
  }

  async onSubmit({processTransaction, incompleteFormMessage='Please complete all fields'}) {
    this.setState({hasSubmitted: true});

    if (!this.state.isSubmitting) {
      this.setState({isSubmitting: true});

      if (this.isValid) {
        var {receiptEmails} = this.props;

        if (receiptEmails) receiptEmails = _.map(receiptEmails.split(','), receiptEmail => receiptEmail.trim());

        var hasInvalidEmail = _.some(receiptEmails, receiptEmail => !lib.validation.emailIsValid(receiptEmail));
        var shouldSubmit = !hasInvalidEmail;

        if (hasInvalidEmail) {
          alert('Email is not valid, please provide either a valid email or no email');
        }
        else if (receiptEmails.length < 1) {
          shouldSubmit = window.confirm('No receipt email provided, are you sure you wish to submit payment?');
        }

        //HINT prevent Amex payments client side (this is also enforced server side)
        if (_.toNumber(_.get(this, 'state.ccNumber[0]')) === 3) {
          shouldSubmit = false;

          alert('Amex is not accepted. Please use another card or payment method');
        }

        if (shouldSubmit) {
          await processTransaction();

          var props = {
            hasSubmitted: false,
            partialPaymentAmount: 0
          };

          _.forEach(_.filter(this.formKeys, key => !_.includes(['accountType'], key)), formKey => props[formKey] = '');

          this.setState(props);
        }
      }
      else {
        alert(incompleteFormMessage);
      }

      this.setState({isSubmitting: false});
    }
  }

  get isValid() {
    return this.props.transaction && (this.invalidKeys.length === 0);
  }

  get invalidKeys() {
    var invalidKeys = [];
    var {state} = this;

    _.forEach(this.formKeys, key => {
      if (!state[key]) invalidKeys.push(key);
    });

    return invalidKeys;
  }

  get paymentAmountInCents() {
    var paymentAmount = 0;

    paymentAmount = _.min([this.state.partialPaymentAmount || 1000000000, this.remainingAmountDue]);

    return paymentAmount;
  }

  get remainingAmountDue() {
    var remainingAmountDue = 0;

    if (this.props.transaction) {
      var {transaction} = this.props;

      remainingAmountDue = transaction.amountInCents - (transaction.amountPaidInCents || 0);
    }

    return remainingAmountDue;
  }

  render(children) {
    var {state} = this;
    var {transaction} = this.props;
    var submitPaymentAmount = 0;

    submitPaymentAmount = lib.price.dinero(this.paymentAmountInCents / 100).toFormat('0.00');

    return (
      <div className='form-container'>
        {transaction &&
          <Form
            useSubmitInput
            on={{submit: this.onSubmit}}
            submitText={`Pay $${submitPaymentAmount} Now`}
            isSubmittingText='Processing Payment'
          >
            <div className='partial-payment-container'>
              {this.props.hasMadePayment && (
                <div className='partial-thank-you-note'>
                  <div>Thank you for your payment, an email receipt will arrive shortly.</div>
                  <div>To continue, just enter the information of the next {this.paymentTypeName} you would like to use.</div>
                </div>
              )}
              <Form.DollarInput
                label= 'Payment Amount ($)'
                value={this.state.partialPaymentAmount || this.remainingAmountDue}
                on={{
                  change: ({value}) => {
                    this.setState({partialPaymentAmount: value})
                  }
                }}
              />
              <div className='partial-payment-note'>you can adjust this amount if you’re paying with multiple {this.paymentTypeName}s</div>
            </div>
            {children}
            <br /><br /><br />
            <div>
              <Form.TextInput {...className([_.includes(this.invalidKeys, this.state.hasSubmitted && 'billingStreetAddress') && 'is-invalid'])} label='Billing Address' placeholder='' value={state.billingStreetAddress} on={{input: this.handleChangeFor({key: 'billingStreetAddress'})}}/>
              <Form.TextInput {...className([_.includes(this.invalidKeys, this.state.hasSubmitted && 'billingCity') && 'is-invalid'])} label='City' placeholder='' value={state.billingCity} on={{input: this.handleChangeFor({key: 'billingCity'})}}/>
              <Form.DropdownInput
                {...className([_.includes(this.invalidKeys, this.state.hasSubmitted && 'billingState') && 'is-invalid'])}
                label='State'
                value={state.billingState}
                on={{change: this.handleChangeFor({key: 'billingState'})}}
                search
                options={[{value: '', title: ''}, ..._.map(lib.formData.states, stateAbbreviation => ({title: stateAbbreviation, value: stateAbbreviation}))]}
              />
              <Form.TextInput {...className([_.includes(this.invalidKeys, this.state.hasSubmitted && 'billingZip') && 'is-invalid'])} label='Zip' placeholder='' value={state.billingZip} on={{input: this.handleChangeFor({key: 'billingZip'})}}/>
            </div>
          </Form>
        }
      </div>
    );
  }
}
