import {_, React, Component, className, lib} from '@henrybuilt/react-app'; //eslint-disable-line
import {Form} from 'henrybuilt-react-library';
import PaymentMethodBody from '../payment-method/payment-method-body/payment-method-body.js';
import './wire-form.scss';

export default class WireForm extends PaymentMethodBody {
  get formKeys() {
    return [
      'wireReferenceNumber'
    ];
  }

  async onSubmit() {
    await super.onSubmit({
      processTransaction: async () => {
        this.props.onClientInitializedPayment({paymentType: 'wire', supportingInfo: this.state.wireReferenceNumber});
      },
      incompleteFormMessage: 'Please enter a wire reference number'
    });
  }

  render() {
    var {transaction, isInitializedByClient, legalCompanyName, companySpecificAccountNumber} = this.props;
    var {state} = this;

    return !transaction ? null : (
      <>
        <div className='static-billing-container wire-reference-container'>
          <div className='static-billing-line-item'>Please Include Your Name and transaction #{transaction.projectId || transaction.id} In The Memo</div>
          <div className='static-billing-line-item'>
            <div className='static-billing-label'>Wires should be sent to:</div>
            <div className='static-billing-content-container'>
              <div className='static-billing-content'>{legalCompanyName}</div>
              <div className='static-billing-content'>Bank of America</div>
              <div className='static-billing-content'>222 Broadway</div>
              <div className='static-billing-content'>New York, NY 10038</div>
            </div>
          </div>
          <div className='static-billing-line-item'>
            <div className='static-billing-label'>Routing/ABA #:</div>
            <div className='static-billing-content-container'>
              <div className='static-billing-content'>026009593</div>
            </div>
          </div>
          <div className='static-billing-line-item'>
            <div className='static-billing-label'>Account #:</div>
            <div className='static-billing-content-container'>
              <div className='static-billing-content'>{companySpecificAccountNumber}</div>
            </div>
          </div>
          <br/>
          {!isInitializedByClient && <div className='wire-reference-number-form-container'>
            <div className='static-billing-line-item'>After a wire has been initiated with your bank, enter the reference number and click the button below</div>
            <Form
              useSubmitInput
              on={{submit: this.onSubmit}}
              submitText={`I initiated a wire for ${lib.price.dinero(this.remainingAmountDue / 100).toFormat('$0,0.00')}`}
              isSubmittingText='Notifying Our Team'
            >
              <Form.TextInput {...className([_.includes(this.invalidKeys, state.hasSubmitted && 'wireReferenceNumber') && 'is-invalid', 'wire-reference-number-input'])} label='Wire/Bank Reference #' placeholder='' value={state.wireReferenceNumber} on={{input: ({value}) => this.setState({wireReferenceNumber: value})}}/>
            </Form>
          </div>}
        </div>
      </>
    );
  }
}
