import {_, React, Component, className, lib} from '@henrybuilt/react-app'; //eslint-disable-line
import {connect, Page} from '@henrybuilt/react-app/src';
import {Form} from 'henrybuilt-react-library';
import Scroll from 'react-scroll';
import {Link} from 'react-router-dom';
import PaymentMethodGroup from './payment-method-group/payment-method-group.js';
import PaymentMethod from './payment-method/payment-method.js';
import CreditCardForm from './credit-card-form/credit-card-form.js';
import ElectronicCheckForm from './electronic-check-form/electronic-check-form.js';
import WireForm from './wire-form/wire-form.js';
import AchForm from './ach-form/ach-form.js';
import CheckForm from './check-form/check-form.js';
import {brandKeyNameMap} from 'helpers/brand/brand-helper.js';
import './show-transaction-page.scss';

class ShowTransactionPage extends Page {
  key = 'show-transaction';
  authGroup = 'anyone';
  state = {isLoading: true, activePaymentMethodGroupKey: 'preferred', activePaymentMethodKey: 'electronicCheck'};

  constructor(props) {
    super(props);

    this.bind(['setActivePaymentMethod', 'onClientInitializedPayment', 'onClientUninitializedPayment']);
  }

  async componentDidMount() {
    var user = _.get(this, 'props.session.user');

    var isEmployee = _.includes(['employee', 'admin'], _.get(user, 'authGroup'));

    var {data} = await lib.api.request({uri: 'transaction-for-token', body: {transactionToken: this.props.match.params.token}});
    var {transaction} = data;

    this.setState({transaction, receiptEmails: transaction.payerEmails, isLoading: false, isEmployee});
  }

  async onClientInitializedPayment({paymentType, supportingInfo}) {
    var {transaction} = this.state;

    var paymentTypeMessage = {
      wire: 'initialized a wire',
      check: `mailed a check`,
      ach: 'initialized an ACH payment'
    }[paymentType] || 'Check Mailed';

    var clientConfirmedInitialization = window.confirm(`This will notify our team that you have ${paymentTypeMessage}. Do you wish to proceed?`);

    if (clientConfirmedInitialization) {
      await lib.api.request({uri: 'transactions/client-initialized-payment', body: {transaction, paymentType, supportingInfo}, useActualErrorMessages: true});

      this.setState((prevState) => {
        return {activePaymentMethodKey: undefined, transaction: {...prevState.transaction, status: 'initializedByClient'}};
      });

      setTimeout(() => Scroll.animateScroll.scrollToTop());
    }
  }

  async onClientUninitializedPayment() {
    var {transaction} = this.state;

    if (transaction && transaction.status === 'initializedByClient') {
      await lib.api.request({uri: 'transactions/client-uninitialized-payment', body: {transaction}, useActualErrorMessages: true});

      this.setState((prevState) => {
        return {activePaymentMethodGroupKey: 'preferred', activePaymentMethodKey: 'electronicCheck', transaction: {...prevState.transaction, status: 'uninitializedByClient'}};
      });
    }
  }

  setActivePaymentMethod({key}) {
    this.setState({activePaymentMethodKey: this.state.activePaymentMethodKey === key ? '' : key});
  }

  get isValid() {
    var {transaction} = this.state;

    return transaction && transaction.amountInCents > 0 && transaction.type && transaction.brandKey;
  }

  get remainingAmountDue() {
    var remainingAmountDue = 0;

    if (this.state.transaction) {
      var {transaction} = this.state;

      remainingAmountDue = transaction.amountInCents - (transaction.amountPaidInCents || 0);
    }

    return remainingAmountDue;
  }

  render() {
    var {transaction, hasMadePayment, receiptEmails, activePaymentMethodKey, activePaymentMethodGroupKey, isLoading, isEmployee} = this.state;

    var onSuccessfulPayment = ({amountPaidInCents}) => {
      this.setState((prevState) => {
        var updatedAmountPaidInCents = amountPaidInCents + (prevState.transaction.amountPaidInCents || 0);
        var updatedStatus = updatedAmountPaidInCents >= prevState.transaction.amountInCents ? 'paid' : 'partiallyPaid';

        return {hasMadePayment: true, transaction: {...prevState.transaction, amountPaidInCents: updatedAmountPaidInCents, status: updatedStatus}};
      });

      if (this.state.transaction.status === 'paid') setTimeout(() => Scroll.animateScroll.scrollToTop());
    }

    var legalCompanyName = transaction && transaction.brandKey === 'st' ? 'Space Theory LLC' : 'Henrybuilt, Inc.';
    var companyName = transaction && transaction.brandKey === 'st' ? 'Space Theory' : 'Henrybuilt';
    var companySpecificAccountNumber = transaction && transaction.brandKey === 'st' ? '138110446552' : '138110603638';
    var companySpecificPOBoxNumber = transaction && transaction.brandKey === 'st' ? '80992' : '80764';
    var isInitializedByClient = transaction && transaction.status === 'initializedByClient';

    var paymentMethodGroups = [
      {
        key: 'preferred',
        title: 'Preferred Payment Methods',
        paymentMethods: [
          {
            key: 'electronicCheck',
            header: 'Electronic Check',
            renderBody: () => <ElectronicCheckForm
              hasMadePayment={hasMadePayment}
              receiptEmails={receiptEmails}
              transaction={transaction}
              onSuccessfulPayment={
                ({amountPaidInCents}) => (onSuccessfulPayment({amountPaidInCents}))
              }
            />
          },
        ]
      },
      {
        key: 'other',
        title: 'Other Payment Options',
        paymentMethods: [
          {
            key: 'wire',
            header: `Wire${isInitializedByClient ? '' : ' (initiated by client)'}`,
            renderBody: () => {
              return (
                <WireForm
                  {...{hasMadePayment, receiptEmails, transaction, isInitializedByClient, legalCompanyName, companyName, companySpecificAccountNumber, companySpecificPOBoxNumber}}
                  onClientInitializedPayment={({paymentType, supportingInfo}) => this.onClientInitializedPayment({paymentType, supportingInfo})}
                />
              );
            }
          },
          {
            key: 'ach',
            header: `ACH Transfer${isInitializedByClient ? '' : ' (initiated by client)'}`,
            renderBody: () => {
              return (
                <AchForm
                  {...{hasMadePayment, receiptEmails, transaction, isInitializedByClient, legalCompanyName, companyName, companySpecificAccountNumber, companySpecificPOBoxNumber}}
                  onClientInitializedPayment={({paymentType, supportingInfo}) => this.onClientInitializedPayment({paymentType, supportingInfo})}
                />
                // <div className='static-billing-container'>
                //   <div className='static-billing-line-item'>Please Include Your Name and transaction #{transaction.projectId || transaction.id} In The Memo</div>
                //   <div className='static-billing-line-item'>
                //     <div className='static-billing-label'>ACH payments should be initiated with your bank and sent to:</div>
                //     <div className='static-billing-content-container'>
                //       <div className='static-billing-content'>{legalCompanyName}</div>
                //       <div className='static-billing-content'>Bank of America</div>
                //       <div className='static-billing-content'>222 Broadway</div>
                //       <div className='static-billing-content'>New York, NY 10038</div>
                //     </div>
                //   </div>
                //   <div className='static-billing-line-item'>
                //     <div className='static-billing-label'>Routing/ABA #:</div>
                //     <div className='static-billing-content-container'>
                //       <div className='static-billing-content'>125000024</div>
                //     </div>
                //   </div>
                //   <div className='static-billing-line-item'>
                //     <div className='static-billing-label'>Account #:</div>
                //     <div className='static-billing-content-container'>
                //       <div className='static-billing-content'>{companySpecificAccountNumber}</div>
                //     </div>
                //   </div>
                //   {!isInitializedByClient && <Form
                //     useSubmitInput
                //     on={{submit: () => this.onClientInitializedPayment({paymentType: 'ach'})}}
                //     submitText={`I initiated an ACH payment for ${lib.price.dinero(this.remainingAmountDue / 100).toFormat('$0,0.00')}`}
                //     isSubmittingText='Notifying Our Team'
                //   />}
                // </div>
              );
            }
          },
          {
            key: 'checkByMail',
            header: `Check${isInitializedByClient ? '' : ' (mailed by client)'}`,
            renderBody: () => {
              return (
                <CheckForm
                  {...{hasMadePayment, receiptEmails, transaction, isInitializedByClient, legalCompanyName, companyName, companySpecificAccountNumber, companySpecificPOBoxNumber}}
                  onClientInitializedPayment={({paymentType, supportingInfo}) => this.onClientInitializedPayment({paymentType, supportingInfo})}
                />
                // <div className='static-billing-container'>
                //   <div className='static-billing-line-item'>Please Include Your Name and transaction #{transaction.projectId || transaction.id} In The Memo</div>
                //   <div className='static-billing-line-item'>
                //     <div className='static-billing-label'>Checks should be mailed via USPS to:</div>
                //     <div className='static-billing-content-container'>
                //       <div className='static-billing-content'>{companyName}</div>
                //       <div className='static-billing-content'>PO Box {companySpecificPOBoxNumber}</div>
                //       <div className='static-billing-content'>Seattle, WA 98108</div>
                //     </div>
                //   </div>
                //   {!isInitializedByClient && <Form
                //     useSubmitInput
                //     on={{submit: () => this.onClientInitializedPayment({paymentType: 'check'})}}
                //     submitText={`I sent a check for ${lib.price.dinero(this.remainingAmountDue / 100).toFormat('$0,0.00')}`}
                //     isSubmittingText='Notifying Our Team'
                //   />}
                // </div>
              );
            }
          },
          {
            key: 'creditCard',
            header: 'Credit Card',
            renderBody: () => <CreditCardForm
              hasMadePayment={hasMadePayment}
              receiptEmails={receiptEmails}
              transaction={transaction}
              onSuccessfulPayment={
                ({amountPaidInCents}) => (onSuccessfulPayment({amountPaidInCents}))
              }
            />
          },
        ]
      }
    ];

    if (transaction && transaction.brandKey === 'hb' && _.includes(['signoff', 'pif'], transaction.type)) {
      paymentMethodGroups = _.map(paymentMethodGroups, paymentMethodGroup => {
        paymentMethodGroup.paymentMethods = _.filter(paymentMethodGroup.paymentMethods, method => !_.includes(['creditCard'], method.key));

        return paymentMethodGroup;
      });
    }

    var paymentMethods = [];

    //HINT want to show client these payment methods, because they are frequently clicking the button before actually sending the payment
    if (isInitializedByClient) {
      paymentMethodGroups = _.map(paymentMethodGroups, paymentMethodGroup => {
        paymentMethodGroup.paymentMethods = _.filter(paymentMethodGroup.paymentMethods, method => _.includes(['checkByMail', 'ach', 'wire'], method.key));

        return paymentMethodGroup;
      });

      paymentMethods = _.flatMap(paymentMethodGroups, paymentMethodGroup => paymentMethodGroup.paymentMethods);
    }

    return super.render(
      <div className='show-transaction-page-content'>
        {!isLoading && (
          <>
            {this.isValid ? (
              <div {...className(['transaction', `status-${transaction && transaction.status}`])}>
                <div className='transaction-info'>
                  <div className='transaction-title'>{transaction.title}</div>
                  {isEmployee && (<div>
                    <Link className='edit-transaction-link' to={`/edit-transaction/${transaction.id}`}>edit transaction</Link>
                  </div>)}
                  {!_.includes(['initializedByClient', 'paid'], transaction.status) && transaction.description && <div className='transaction-description'>{transaction.description}</div>}
                  <div className='transaction-amount-status'>
                    {(transaction.status !== 'paid' && transaction.amountPaidInCents) ? `${lib.price.dinero(transaction.amountPaidInCents / 100).toFormat('$0,0.00')} / ` : ''}{lib.price.dinero(transaction.amountInCents / 100).toFormat('$0,0.00')}{transaction.status === 'paid' ? ' - paid' : ''}
                  </div>
                  {transaction.fileUrl && <a className='file-link button' href={transaction.fileUrl} rel='noopener noreferrer' target='_blank'>View Invoice</a>}
                  {!_.includes(['paid', 'initializedByClient'], transaction.status) && (
                    <div>
                      <Form.TextInput
                        value={transaction.payerEmails}
                        label='Receipt Email'
                        placeholder='address@domain.com'
                        on={{change: ({value}) => this.setState({receiptEmails: value})}}
                      />
                    </div>
                  )}
                </div>
                {!_.includes(['paid', 'initializedByClient'], transaction.status) && (
                  <div className='payment-methods'>
                    {_.map(paymentMethodGroups, paymentMethodGroup => {
                      var {key} = paymentMethodGroup;

                      return (
                        <PaymentMethodGroup
                          {...{key, paymentMethodGroup, activePaymentMethodKey}}
                          isActive={activePaymentMethodGroupKey === key}
                          setActivePaymentMethod={this.setActivePaymentMethod}
                          onSetActive={() => this.setState({activePaymentMethodGroupKey: activePaymentMethodGroupKey === key ? '' : key})}
                        />
                      );
                    })}
                  </div>
                )}
                {transaction.status === 'paid' && (
                  <div className='thank-you-note'>
                    <div>Thank you for your payment.</div>
                    <div>An email receipt will arrive shortly.</div>
                  </div>
                )}
                {isInitializedByClient && (
                  <div>
                    <div {...className(['initialized-thank-you-note'])}>
                      <div className='initialized-thank-you-note-text'>Thank you for initializing payment.</div>
                      <div className='initialized-thank-you-note-text'>Your payment will be processed when it is received.</div>
                      <br /><br />
                      <div className='initialized-additional-info-container'>
                        <div>Wire, ACH, and Check instructions are listed below for your convenience:</div>
                        {_.map(paymentMethods, paymentMethod => {
                          return <PaymentMethod
                            paymentMethod={paymentMethod}
                            isActive={activePaymentMethodKey === paymentMethod.key}
                            onSetActive={this.setActivePaymentMethod}
                            key={paymentMethod.key}
                          />})}
                        <div className='button' onClick={this.onClientUninitializedPayment}>I'd like to pay using another method</div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className='transaction-not-prepared-message'>
                We're sorry, but this transaction is not fully setup yet.
              </div>
            )}
            {transaction && (
              <div className='support-contact-info'>
                <div>For Support please call or email your {brandKeyNameMap[transaction.brandKey === 'st' ? 'st' : 'hb']} contact</div>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

export default connect({})(ShowTransactionPage);
